import { DateRange } from '../analytics';
import { apiFetch, HTTPMethod } from '../fetch';
import { DigitalCustomsArticle, DigitalCustomsArticleAndConsolidation, DigitalCustomsConsolidation, DigitalCustomsConsolidationError, DigitalCustomsOrder } from './interfaces';

export const getCompanyDigitalCustomsOrdersByDate = (companyId: string, dateRange: DateRange, isTest: boolean = false ) =>
  apiFetch<DigitalCustomsOrder []>(`/DigitalCustoms/${companyId}/orders?from=${dateRange.from}&to=${dateRange.to}&isTest=${isTest}`);

export const getCompanyDigitalCustomsArticlesByOrderId = (companyId: string, orderId: string) =>
  apiFetch<DigitalCustomsArticle []>(`/DigitalCustoms/${companyId}/articlesByOrderId?orderId=${orderId}`);

export const getCompanyDigitalCustomsConsolidationsNotSentToMymo = (companyId: string, isTest: boolean = false) =>
  apiFetch<DigitalCustomsConsolidation []>(`/DigitalCustoms/${companyId}/consolidationsNotSentToMymo?isTest=${isTest}`);

export const getCompanyDigitalCustomsArticlesByConsolidationId = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsArticle []>(`/DigitalCustoms/${companyId}/articlesByConsolidationId?consolidationId=${consolidationId}`);

export const putCompanyDigitalCustomsArticleHsCode = (companyId: string, articleId: string, newHsCode: string) =>
  apiFetch<DigitalCustomsArticleAndConsolidation>(`/DigitalCustoms/${companyId}/articleHsCode?articleId=${articleId}`, newHsCode, HTTPMethod.PUT);

export const sendConsolidationToMymo = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidation>(`/DigitalCustoms/${companyId}/sendConsolidationToMymo/${consolidationId}`, undefined, HTTPMethod.PUT);

export const getNumberOfConsolidationsCorrectionsForCompany = (companyId: string, isTest: boolean = false) =>
  apiFetch<number>(`/DigitalCustoms/${companyId}/numberOfConsolidationsCorrections?isTest=${isTest}`);

export const getConsolidationErrors = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidationError []>(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}/consolidationErrors`);

export const getConsolidation = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidation>(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}`);

export const deleteConsolidationsCorrection = (companyId: string, consolidationId: string): Promise<any> =>
  apiFetch(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}`, undefined, HTTPMethod.DELETE);