import { AdvancedFee } from '../advancedFees';
import { AmlKybDocument } from '../amlKyb';
import { CompanyAccountManager } from '../companyAccountManagers';
import { CrmCompanyType } from '../crmCompanies';
import { SubscriptionModule } from '../subscriptions';

export enum CompanyCategory {
  NotSelected = 'NotSelected',
  Carrier = 'Carrier',
  CustomsBroker = 'CustomsBroker',
  Merchant = 'Merchant',
  PaymentServiceProvider = 'PaymentServiceProvider',
  ThirdPartyLogistics = 'ThirdPartyLogistics',
  Labeling = 'Labeling',
  IossReseller = 'IossReseller',
  ProductClassifier = 'ProductClassifier',
  Test = 'Test',
}

export interface CompanyIdAndName{
  id: string,
  name: string,
}

export interface WebShop {
  id?: string;
  name?: string;
  description?: string;
  companies?: any[]; // Define companies
  companyRelationships?: any[]; // Define companyRelationships,
}

export enum PaymentTerm {
  DaysOfCredit7 = 7,
  DaysOfCredit10 = 10,
  DaysOfCredit30 = 30,
  DaysOfCredit60 = 60,
  DaysOfCredit90 = 90,
}

export enum IossAdvancedFeeName {
  TenPercentIossVatFee = '10% IOSS VAT fee (maximum 1€ per order row)',
  FivePercentIossVatFee = '5% IOSS VAT fee (maximum 1€ per order row)',
  DefaultPercentIossVatFee = 'Default IOSS VAT fee (1€ per order row)',
  EnionIossVatFee = 'Enion IOSS VAT fee (10% or max 0.75€ per order row)',
  NoAdvancedFees = 'null',
}

export const companyOnboardingStatusOrder = [
  0,
  1,
  6,
  7,
  2,
  3,
  5,
  4,
];

export enum CompanyOnboardingStatus {
  Onboarding = 0,
  OnboardingComplete = 1,
  InvoiceSent = 2,
  InvoicePaid = 3,
  IossNumberSet = 4,
  IossNumberRequested = 5,
  ERPCustomerCreated = 6,
  InvoiceCreated = 7,
}

export enum CompanyIossStatus {
  None = 0,
  ReadyForIoss = 1,
  IossNumberRequested = 2,
  IossNumberSet = 3,
  IossNumberRevoked = 4,
  IossNumberExpired = 5,
}

export const paymentTerms = [7, 10, 30, 60, 90];

export enum StatementPaymentProvider{
  None = 0,
  Plusius = 1,
}
export interface Billing {
  id: string;
  paymentTerm?: PaymentTerm;
  currencyCode?: string;
  invoiceEmailAddresses?: BillingInformationEmailAddress [];
  optionalReference?: string,
  customerNumber: number;
  statementPaymentProvider: StatementPaymentProvider;
  iban?: string;
  bicSwift?: string;
}

export interface BillingInformationEmailAddress {
  id?: string,
  emailAddress: string
}

export interface CompanyBackendModel {
  id: string;
  name?: string;
  number?: string;
  vatNumber?: string;
  email?: string;
  soleTrader: boolean,
  addresses: Address[];
  companyWebShops?: WebShop[];
  companyContacts?: CompanyContact[];
  companyCompanyRelationships?: CompanyCompanyRelationships[];
  phones?: Phone[];
  web?: string;
  urlPrefix?: string;
  billingInformation: Billing;
  companyCategories: CompanyCategory[];
  validationIssues?: string[];
  addedUtc?: string;
  activeCompanySubscriptionModules?: SubscriptionModule[];
  revokedUtc?: string;
  listOfTransportCarriers?: string; // Merchant
  estimateDailyShipments?: number; // Merchant
  eCommercePlatform?: string // Merchant
  iossNumber?: string; // Merchant
  iossComment?: string; // Merchant
  iossStartDate?: string; // Merchant
  iossEndDate?: string; // Merchant
  iossNumberHistory?: string [];
  referrerCompanyId?: string;
  iossAdvancedFeeName?: string;
  paymentTerm?: PaymentTerm;
  customerNumber: string;
  onboardingStatus: CompanyOnboardingStatus;
  crmCompanyType: CrmCompanyType;
  companyAccountManagers: CompanyAccountManager[];
  webhookUpdatesUrl?: string;
  webhookUpdatesUrlToken?: string;
  hasCompanyNotes: boolean;
  hasActiveAutoDebit: boolean;
  iossStatus: CompanyIossStatus;
  advancedFees?: AdvancedFee[];
}

export interface CreateCompany {
  name: string
  email: string
  companyCategories: CompanyCategory[];
  crmCompanyType: CrmCompanyType;
}

// Partial company is used in lists for example
export interface PartialCompany extends Pick<
CompanyBackendModel,
'id'
| 'email'
| 'addedUtc'
| 'revokedUtc'
| 'companyCategories'
| 'validationIssues'
| 'iossNumber'
| 'iossComment'
| 'iossNumberHistory'
| 'customerNumber'
| 'onboardingStatus'
| 'name'
| 'estimateDailyShipments'
| 'iossAdvancedFeeName'
| 'eCommercePlatform'
| 'crmCompanyType'
| 'companyAccountManagers'
| 'hasCompanyNotes'
| 'hasActiveAutoDebit'
| 'iossStatus'
> {}

// Company frontend model
export interface Company extends Omit<CompanyBackendModel, 'addresses'> {
  // Assigning the different address types to specific properties
  // because it's easier to work with and at the moment a company cannot
  // add multiple addresses of the same type.
  postalAddress?: Address,
  invoiceBillingAddress?: Address,
  visitAddress?: Address,
}

export enum CompanyContactType {
  NotSelected = 'NotSelected',
  Billing = 'Billing',
  Technical = 'Technical',
  Operational = 'Operational',
  Director = 'Director',
  BeneficialOwner = 'BeneficialOwner',
}

export interface CompanyContact {
  id?: string;
  contactTitle?: string,
  firstName?: string;
  lastName?: string;
  personalId?: string,
  ownerPercentage?: number,
  nationality?: string,
  email?: string;
  emailStatus?: EmailStatus;
  phone?: string;
  companyContactTypes?: CompanyContactType [];
  amlKybDocuments?: AmlKybDocument [],
  addresses?: Address[];
}

export enum EmailStatus {
  NoVerificationNeeded = 'NoVerificationNeeded',
  NotVerified = 'NotVerified',
  Verified = 'Verified',
}

export interface CompanyCompanyRelationships {
  id?: string;
  companyName?: string;
  companyId?: string;
}

export enum AddressType {
  Visit = 'Visit',
  Postal = 'Postal',
  InvoiceBilling = 'InvoiceBilling',
}

export interface Address {
  id: string;
  addressTypes: AddressType[];
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
}

export enum PhoneType {
  NotNoneSelected = 0,
  CompanyMain = 1,
  Direct = 2,
  Mobile = 3,
}

export interface Phone {
  phoneId?: string;
  phoneType: PhoneType;
  number: string;
}

export interface ZuluCustomer{
  customerReference: string;
  companyName: string;
  companyId: string;
  publicUrl: string;
  streetAddress: string;
  zipCode: string;
  countryCode: string;
}

export interface CompanyEmail{
  to: string;
  isSent: boolean;
  body?: string;
  subject?: string;
}