import { useCallback, useEffect, useMemo } from 'react';
import { useCrossborderitApi } from '../useCrossborderitApi';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { ActiveStatus, Customer } from './interfaces';
import { HTTPMethod } from 'integrations/crossborderit/fetch';
import { replaceBy } from 'utility';

export const useCustomers = (companyId: string) => {
  const storePath = useMemo(() => ['companyCustomers', companyId], [companyId]);
  const { api } = useCrossborderitApi();
  const dispatch = useDispatch();

  const {
    data: customers = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<Customer[]>([], storePath));

  const inactiveCustomers = useMemo(
    () => customers.filter(customer => !customer.companyIsActive),
    [customers]
  );

  const activeCustomers = useMemo(
    () => customers.filter(customer => customer.companyIsActive),
    [customers]
  );

  const loadCustomers = useCallback((activeStatus: ActiveStatus) => {
    if (!companyId) return;

    dispatch(setAsyncResource(
      storePath,
      AsyncStatus.Loading,
      []
    ));

    api<Customer[]>(`/companies/${companyId}/relationships?activeStatus=${activeStatus}`)
      .then(customers => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        customers
      )))
      .catch(() => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Error,
        []
      )));
  }, [api, dispatch, storePath, companyId]);

  const loadCustomer = useCallback((customerId: string) =>
    api<Customer>(`/companies/${companyId}/relationships/${customerId}`)
      .then(customer => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        replaceBy('id', customer, customers)
      )))
  , [api, dispatch, storePath, companyId, customers]);

  const createCustomer = useCallback((customer: Customer) =>
    api<Customer>(`/companies/${companyId}/relationships`, customer, HTTPMethod.POST)
      .then(newCustomer => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        [newCustomer, ...customers]
      )))
  , [api, dispatch, storePath, companyId, customers]);

  const inactivateCustomer = useCallback((customerId: string) =>
    api(`/companies/${companyId}/relationships/${customerId}/inactivate`, undefined, HTTPMethod.PUT)
      .then(() => loadCustomer(customerId))
  , [api, companyId, loadCustomer]);

  const reactivateCustomer = useCallback((customerId: string) =>
    api(`/companies/${companyId}/relationships/${customerId}/reactivate`, undefined, HTTPMethod.PUT)
      .then(() => loadCustomer(customerId))
  , [api, companyId, loadCustomer]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadCustomers(ActiveStatus.All);
  }, [status, loadCustomers]);

  return {
    status,
    customers: customers || [],
    activeCustomers,
    inactiveCustomers,
    loadCustomers,
    loadCustomer,
    createCustomer,
    inactivateCustomer,
    reactivateCustomer,
  };

};
