import { authService } from 'integrations/crossborderit/authorization';
import {
  HTTPMethod,
  HTTPStatus,
  AuthStatus,
} from 'integrations/crossborderit/fetch';

// TODO: merge this apiFetch with apiFetch in ./fetch
/* eslint-disable no-throw-literal */
export const apiFetchFormData = async <T>(endpoint: string, body?: any, method: HTTPMethod = HTTPMethod.GET): Promise<T> => {

  const errorMessageConverter = (errorMessage: string) => {
    if (/[R][e][q][u][e][s][t][ ][b][o][d][y][ ][t][o][o][ ][l][a][r][g][e]/.test(errorMessage))
    { return 'File size must be under 28MB'; }
    return false;
  };

  const isAuthenticated = await authService.isAuthenticated();
  if (!isAuthenticated) throw { status: AuthStatus.NotAuthenticated };

  const token = await authService.getAccessToken();
  const url = `${process.env.PUBLIC_URL}/api${endpoint}`;

  const apiResponse = await fetch(url, {
    method,
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (apiResponse.status === HTTPStatus.Unauthorized) {
    throw { status: AuthStatus.NotAuthenticated };
  } else if (apiResponse.ok) {
    return apiResponse?.headers?.get('Content-Type')?.includes('application/json')
      ? apiResponse.json()
      : apiResponse;
  } else {
    let errorMessage = await apiResponse.text();
    if (errorMessageConverter(errorMessage)){
      errorMessage = errorMessageConverter(errorMessage) as string;
    }
    else if (!errorMessage || errorMessage?.length > 100 ){
      errorMessage = `Something unexpected happened when trying to fetch ${url}`;
    }
    throw new Error(errorMessage);
  }
};
