import {
  EarningsAndVatByCompanyAndDate,
  IossRegistrationsByPeriod,
  IossRegistrationsByPeriodAndCountry,
  SalesByPeriod,
  SalesByPeriodAdmin,
  SalesByPeriodAndDestination,
  DestinationCountriesForEachCompany,
  AllDestinationCountries,
  PlatformType,
  AnalyticsQuery,
  ClassitUploadedProductsByTypeAndCompany,
  ClassitProductCountByCompany,
  ClassitClassifiedProductsByTypeAndCompany,
  ClassitClassifyingByTypeAndCompany,
  ClassitCompletedClassificationJobs,
  DigitalCustomsOrdersAnalytics,
  DigitalCustomsOrdersByCustomsStatusAnalytics,
  DigitalCustomsOrdersWithCorrections,
  DigitalCustomsDayWiseReportOrders,
} from '.';
import { apiFetch } from '../fetch';

const queryString = require('query-string');

export const getSalesByPeriod = (
  companyId: string,
  from: string,
  to: string
): Promise<SalesByPeriod[]> =>
  apiFetch(`/Company/${companyId}/Analytics/SalesByPeriod?fromDate=${from}&toDate=${to}`);

export const getSalesByPeriodAndDestination = (
  companyId: string,
  from: string,
  to: string,
  destinationCountry: string
): Promise<SalesByPeriodAndDestination[]> =>
  apiFetch(
    `/Company/${companyId}/Analytics/SalesByPeriodAndDestination?fromDate=${from}&toDate=${to}&destinationCountry=${destinationCountry}`
  );

export const getSalesByPeriodAdmin = (
  from: string,
  to: string,
  platform: PlatformType = PlatformType.All
): Promise<SalesByPeriodAdmin[]> =>
  apiFetch(`/Analytics/SalesByPeriod?fromDate=${from}&toDate=${to}&platform=${platform}`);

export const getIossRegistrationsByPeriod = (
  from: string,
  to: string
): Promise<IossRegistrationsByPeriod[]> =>
  apiFetch(`/Analytics/IossRegistrationsByPeriod?fromDate=${from}&toDate=${to}`);

export const getIossRegistrationsByPeriodAndCountry = (
  from: string,
  to: string
): Promise<IossRegistrationsByPeriodAndCountry[]> =>
  apiFetch(`/Analytics/IossRegistrationsByPeriodAndCountry?fromDate=${from}&toDate=${to}`);

export const getEarningsAndVatByCompanyAndDate = (from: string, to: string, feeType: string, platform: PlatformType = PlatformType.All): Promise<EarningsAndVatByCompanyAndDate> =>
  apiFetch(`/Analytics/EarningsAndVatByCompanyAndDate?fromDate=${from}&toDate=${to}&advancedFeeId=${feeType}&platform=${platform}`);

export const getEarningsAndVatByCompanyAndDateAllFeeTypes = (from: string, to: string, platform: PlatformType = PlatformType.All): Promise<EarningsAndVatByCompanyAndDate> =>
  apiFetch(`/Analytics/EarningsAndVatByCompanyAndDate?fromDate=${from}&toDate=${to}&platform=${platform}`);

export const getDestinationCountriesForEachCompany = (companyId: string, from: string, to: string
): Promise<DestinationCountriesForEachCompany> =>
  apiFetch(`/Company/${companyId}/Analytics/DestinationCountriesForEachCompany?fromDate=${from}&toDate=${to}`);

export const getAllDestinationCountries = (from: string, to: string, platform: PlatformType = PlatformType.All): Promise<AllDestinationCountries> =>
  apiFetch(`/Analytics/AllDestinationCountries?fromDate=${from}&toDate=${to}&platform=${platform}`);

export const getProductCountAsync = (query: AnalyticsQuery): Promise<ClassitProductCountByCompany[]> => apiFetch(`/Analytics/products/count${!!query ? `?${queryString.stringify(query)}` : ''
}`);

export const getUploadedProductCountAsync = (query: AnalyticsQuery): Promise<ClassitUploadedProductsByTypeAndCompany[]> => apiFetch(`/Analytics/uploadedproducts${!!query ? `?${queryString.stringify(query)}` : ''
}`);

export const getClassfiedProductCountAsync = (query: AnalyticsQuery): Promise<ClassitClassifiedProductsByTypeAndCompany[]> => apiFetch(`/Analytics/classifiedproducts${!!query ? `?${queryString.stringify(query)}` : ''
}`);

export const getInProgressClassficationsAsync = (query: AnalyticsQuery): Promise<ClassitClassifyingByTypeAndCompany[]> => apiFetch(`/Analytics/inprogressclassifications${!!query ? `?${queryString.stringify(query)}` : ''
}`);

export const getCompletedClassificationJobs = (query: AnalyticsQuery): Promise<ClassitCompletedClassificationJobs[]> => apiFetch(`/Analytics/completedclassificationjobs${!!query ? `?${queryString.stringify(query)}` : ''
}`);

export const getReportForDigitalCustomsOrders = (query: AnalyticsQuery): Promise<DigitalCustomsOrdersAnalytics[]> => apiFetch(`/Analytics/digitalCustomsOrders${!!query ? `?${queryString.stringify(query)}` : ''}`);

export const getReportForDigitalCustomsOrdersByCustomsStatus = (query: AnalyticsQuery): Promise<DigitalCustomsOrdersByCustomsStatusAnalytics[]> => apiFetch(`/Analytics/digitalCustomsOrders/customsStatus${!!query ? `?${queryString.stringify(query)}` : ''}`);

export const getReportForDigitalCustomsOrdersWithCorrections = (query: AnalyticsQuery): Promise<DigitalCustomsOrdersWithCorrections[]> => apiFetch(`/Analytics/digitalCustomsOrders/corrections${!!query ? `?${queryString.stringify(query)}` : ''}`);

export const getDayWiseReportForDigitalCustomsOrders = (query: AnalyticsQuery): Promise<DigitalCustomsDayWiseReportOrders[]> => apiFetch(`/Analytics/digitalCustomsOrders/dayWiseReport${!!query ? `?${queryString.stringify(query)}` : ''}`);