import { HsCodeValidationStatus, PartialProduct, ProductClassificationStatus } from './interfaces';

export interface ProductListContainerBackEndModel {
  productList: productBackEndModel[];
  totalNumberOfMatchingEntriesInDb: number;
}

interface productBackEndModel {
  id: string; // Id
  cId: string; // CompanyId
  a: string; // ArticleNumber
  n?: string; // Name
  d?: string; // Description
  c?: string; // CommodityType
  cf?: number; //Confidence
  hsba?: string; //HsCodeSuggestionByAI
  h?: string; // HsCode
  hvs?: HsCodeValidationStatus; // HsCodeValidationStatus
  hvsd?: string; // HsCodeValidationStatusUtcDate
  hId?: string; // HsCodeId
  h2?: string; // HsCode2
  h2vs?: HsCodeValidationStatus; // HsCode2ValidationStatus
  h2vsd: string; // HsCode2ValidationStatusUtcDate
  h2Id?: string; // HsCode2Id
  h3?: string; // HsCode3
  h3vs?: HsCodeValidationStatus; // HsCode3ValidationStatus
  h3vsd?: string; // HsCode2ValidationStatusUtcDate
  h3Id?: string; // HsCode2Id
  i?: string; // ImageUrl
  p?: string; // ProductUrl
  lu: string; // LastUpdated
  ub: string; // UpdatedBy
  s?: string; // ShipmentOriginCountryCode
  cs?: ProductClassificationStatus; // ClassificationStatus
  aTfN?: string; //AssignToFullName
  aTi?: string; //AssignedToId
  at: boolean; //AiTranslated
  t6?: string; //Trade6HsCode
  t6vs?: HsCodeValidationStatus; //Trade6ValidationStatus
  t6vsd?: string; //Trade6ValidationStatusUtcDate
  t6id?: string; //Trade6Id
}

export const productListBackendModelToPartialProducts = (productListFromBackEnd: productBackEndModel[]) => {
  const productList: PartialProduct[] = productListFromBackEnd.map((product: productBackEndModel) => ({
    id: product.id,
    companyId: product.cId,
    articleNumber: product.a,
    name: product.n,
    description: product.d,
    commodityType: product.c,
    confidence: product.cf,
    hsCode: product.h,
    hsCodeValidationStatus: product.hvs,
    hsCodeValidationStatusUtcDate: product.hvsd,
    hsCodeId: product.hId,
    hsCode2: product.h2,
    hsCode2ValidationStatus: product.h2vs,
    hsCode2ValidationStatusUtcDate: product.h2vsd,
    hsCode2Id: product.h2Id,
    hsCode3: product.h3,
    hsCode3ValidationStatus: product.h3vs,
    hsCode3ValidationStatusUtcDate: product.h3vsd,
    hsCode3Id: product.h3Id,
    hsCodeSuggestionByAI: product.hsba,
    imageUrl: product.i,
    productUrl: product.p,
    lastUpdated: product.lu,
    updatedBy: product.ub,
    shipmentOriginCountryCode: product.s,
    classificationStatus: product.cs,
    assignedToFullName:product.aTfN,
    assignedToId: product.aTi,
    aiTranslated: product.at,
    trade6hsCode: product.t6,
    trade6ValidationStatus: product.t6vs,
    trade6ValidationStatusUtcDate: product.t6vsd,
    trade6Id: product.t6id,
  }));

  return productList;
};