import { memoizeWith, identity, intersection, pathOr } from 'ramda';
import { AsyncStatus, AsyncResource } from 'store/interfaces';
import {
  User,
  UserRole,
  Currency,
  Company,
  CompanyUser,
  Invitation,
  Agreement,
  PageOptions,
  CompanyContact,
} from 'integrations/crossborderit';
import {
  Selector,
  SelectorWithArgument,
} from './selector';

export const selectAppStatus: Selector<AsyncStatus> = (state) =>
  state?.status || AsyncStatus.NotInitialized;

export const selectCurrencies: Selector<Currency[]> = (state) =>
  state?.currencies || [];

export const selectCurrency: SelectorWithArgument<
string,
Currency | undefined
> = (currencyCode) => (state) => {
  const currencies = state?.currencies || [];

  const findCurrency = memoizeWith(identity, (id: string):
  | Currency
  | undefined => currencies.find((currency) => currency.alpha3Code === id));

  return findCurrency(currencyCode);
};

export const isUserInRole = (
  user?: User,
  requiredRoles: string[] = []
): boolean => {
  const userRoles = user?.role || [];
  return !!intersection(userRoles, requiredRoles).length;
};

export const selectIsUserInRole: SelectorWithArgument<UserRole, boolean>
  = (requiredRole) => (state) =>
    isUserInRole(state?.user, [requiredRole]);

export const selectCompanyId: Selector<{companyId: string}> = (state) => {
  const allCompanyIds = Object.keys(state.companies as {
    [companyId: string]: AsyncResource<Company>;
  });
  const companyId = allCompanyIds[allCompanyIds.length - 1];
  return { companyId };
};

export const selectCompany: SelectorWithArgument<
string,
{ company: Company; status: AsyncStatus }
> = (companyId) => (state) => {
  const asyncCompany = state?.companies?.[companyId];
  const status = asyncCompany?.status || AsyncStatus.NotInitialized;
  const company = asyncCompany?.data || ({} as Company);
  return { status, company };
};

export const selectCompanyUsers: SelectorWithArgument<
string,
{ status: AsyncStatus; users: CompanyUser[] }
> = (companyId) => (state) => {
  const asyncUsers = state?.companyUsers?.[companyId];
  const status = asyncUsers?.status || AsyncStatus.NotInitialized;
  const users = asyncUsers?.data || [];
  return { status, users };
};

export const selectCompanyContacts: SelectorWithArgument<
string,
{ status: AsyncStatus; contacts: CompanyContact[] }
> = (companyId) => (state) => {
  const asyncContacts = state?.companyContacts?.[companyId];
  const status = asyncContacts?.status || AsyncStatus.NotInitialized;
  const contacts = asyncContacts?.data || [];
  return { status, contacts };
};

export const selectAdminData: Selector<{
  status: AsyncStatus;
  companyInvitations: Invitation[];
}> = (state) => {
  const asyncAdminData = state?.admin;
  const status = asyncAdminData?.status || AsyncStatus.NotInitialized;
  const companyInvitations = asyncAdminData?.companyInvitations || [];
  return { status, companyInvitations };
};

export const selectPageOptionsData: Selector<{
  pageOptions: PageOptions;
}> = (state) => {
  const asyncPageOptions = state;
  const pageOptions = {
    status: asyncPageOptions.pageOptions!.status || AsyncStatus.NotInitialized,
    data: asyncPageOptions.pageOptions!.data || [],
  };
  return { pageOptions };
};

export const selectPendingCompanyAgreements: SelectorWithArgument<
string,
{ status: AsyncStatus; agreements: Agreement[] }
> = (companyId: string) => (state) => {
  const asyncAgreements
    = state?.companyAgreements?.[companyId]?.pendingAgreements;
  const status = asyncAgreements?.status || AsyncStatus.NotInitialized;
  const agreements = asyncAgreements?.data || [];
  return { status, agreements };
};

export const selectSignedCompanyAgreements: SelectorWithArgument<
string,
{ status: AsyncStatus; agreements: Agreement[] }
> = (companyId: string) => (state) => {
  const asyncAgreements
    = state?.companyAgreements?.[companyId]?.signedAgreements;
  const status = asyncAgreements?.status || AsyncStatus.NotInitialized;
  const agreements = asyncAgreements?.data || [];
  return { status, agreements };
};

export const selectNumberOfCompanyTestConsolidationsCorrections: SelectorWithArgument<
string,
{ status: AsyncStatus; numberOfCompanyTestConsolidationsCorrections: number }
> = (companyId: string) => (state) => {
  const asyncNumberOfCompanyTestConsolidationsCorrections = state?.numberOfCompanyTestConsolidationsCorrections?.[companyId];
  const status = asyncNumberOfCompanyTestConsolidationsCorrections?.status || AsyncStatus.NotInitialized;
  const numberOfCompanyTestConsolidationsCorrections = asyncNumberOfCompanyTestConsolidationsCorrections?.data || 0;
  return { status, numberOfCompanyTestConsolidationsCorrections };
};

export const selectNumberOfCompanyConsolidationsCorrections: SelectorWithArgument<
string,
{ status: AsyncStatus; numberOfCompanyConsolidationsCorrections: number }
> = (companyId: string) => (state) => {
  const asyncNumberOfCompanyConsolidationsCorrections = state?.numberOfCompanyConsolidationsCorrections?.[companyId];
  const status = asyncNumberOfCompanyConsolidationsCorrections?.status || AsyncStatus.NotInitialized;
  const numberOfCompanyConsolidationsCorrections = asyncNumberOfCompanyConsolidationsCorrections?.data || 0;
  return { status, numberOfCompanyConsolidationsCorrections };
};

export const selectAsyncResource
  = <T>(
    fallbackData: T,
    storePath: (string | number)[]
  ): Selector<AsyncResource<T>> =>
    (state) => {
      const fallbackResource = {
        status: AsyncStatus.NotInitialized,
        data: fallbackData,
      };

      const asyncResource: AsyncResource<T> = pathOr(
        fallbackResource,
        storePath,
        state
      );

      const status = asyncResource?.status || AsyncStatus.NotInitialized;
      const data = asyncResource?.data || fallbackData;

      return { status, data } as {
        status: AsyncStatus;
        data: T;
      };
    };