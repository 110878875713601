import { AssignClassificationJobProducts } from '../classificationJobs';
import { apiFetch, HTTPMethod } from '../fetch';
import { GoogleTranslateLanguage } from '../googleCloud/translate';
import {
  Product,
  PartialProduct,
  ProductListContainerModel,
  ProductsQuery,
  Classification,
  PartialClassification,
  ProductBulkClassification,
  ProductBulkClassificationResponse,
  Search,
  UpdateStatuses,
  UpdatedStatus,
  ValidateHsCodesAsyncResponse,
  ProductListInfoHsCodeItem,
  SaveValidation,
  HsCodeValidationItem,
  HsCodeValidationBatch,
  ProductClassificationFromAiQuery,
} from './interfaces';
// TypeScript complains that there are no types for query-string if import keyword is used.
const queryString = require('query-string');

export const getProducts = (
  companyId: string,
  query?: ProductsQuery,
  search?: Search[]
): Promise<ProductListContainerModel> =>
  apiFetch<ProductListContainerModel>(
    `/companies/${companyId}/products${
      !!query ? `?${queryString.stringify(query)}` : ''
    }`,
    search,
    HTTPMethod.PUT
  );

export const getProduct = (
  companyId: string,
  productId: string
): Promise<Product> =>
  apiFetch<Product>(`/companies/${companyId}/products/${productId}`);

export const putProduct = (
  companyId: string,
  product: Product
): Promise<Product> =>
  apiFetch<Product>(
    `/companies/${companyId}/products/${product.id}`,
    product,
    HTTPMethod.PUT
  );

export const assignClassificationJobProductsToAgents = (
  companyId: string,
  jobId: string,
  assignedJobProducts: AssignClassificationJobProducts
): Promise<PartialProduct[]> =>
  apiFetch<PartialProduct[]>( `/companies/${companyId}/products/classificationJobs/${jobId}/assignClassificationJobProducts`, assignedJobProducts, HTTPMethod.PUT);

export const getClassifications = (
  companyId: string,
  productId: string
): Promise<PartialClassification[]> =>
  apiFetch<PartialClassification[]>(
    `/companies/${companyId}/products/${productId}/classifications`
  );

export const putClassification = (
  companyId: string,
  classification: Classification
): Promise<ProductListInfoHsCodeItem> =>
  apiFetch<ProductListInfoHsCodeItem>(
    `/companies/${companyId}/products/${classification.productId}/classifications`,
    classification,
    HTTPMethod.PUT
  );

export const putClassifications = (
  companyId: string,
  bulkClassification: ProductBulkClassification[]
) =>
  apiFetch<ProductBulkClassificationResponse>(
    `/companies/${companyId}/products/classifications/bulk/`,
    bulkClassification,
    HTTPMethod.PUT
  );

export const classifyProduct = (
  companyId: string,
  product: PartialProduct,
  hsCode: string,
  countryCode?: string,
  isTrade6HsCode?: boolean
  // eslint-disable-next-line max-params
) =>
  putClassification(companyId, {
    hsCode,
    productId: product.id,
    countryCode: countryCode || '',
    isTrade6HsCode: isTrade6HsCode,
  });

export const bulkUpdateProductClassificationStatusAsync = (
  companyId: string,
  updatedStatuses: UpdateStatuses
): Promise<UpdatedStatus[]> =>
  apiFetch<UpdatedStatus[]>(
    `/companies/${companyId}/products/bulk/classificationStatus`,
    updatedStatuses,
    HTTPMethod.POST
  );

export const getValidateHsCodesAsync = (
  companyId: string,
  productClassificationIds: string[]
): Promise<ValidateHsCodesAsyncResponse[]> =>
  apiFetch<ValidateHsCodesAsyncResponse[]>(
    `/companies/${companyId}/products/productClassifications/validate`,
    productClassificationIds,
    HTTPMethod.POST
  );
export const deleteProducts = (
  companyId: string,
  productIdList: string
): Promise<Response> =>
  apiFetch(
    `/companies/${companyId}/products?productIdList=${productIdList}`, undefined, HTTPMethod.DELETE
  );

export const getValidateHsCodeAsync = (
  companyId: string,
  productClassificationId: string
): Promise<ValidateHsCodesAsyncResponse> =>
  apiFetch<ValidateHsCodesAsyncResponse>(
    `/companies/${companyId}/products/productClassifications/validate/${productClassificationId}`,
    undefined,
    HTTPMethod.POST
  );

export const getTemporaryProducts = (companyId: string) =>
  apiFetch<Product[]>(`/companies/${companyId}/productimport/companyTemporaryProducts`);

export const checkForTemporaryProducts = (companyId: string) =>
  apiFetch<boolean>(`/companies/${companyId}/productimport/companyTemporaryProducts/check`);

export const handleTemporaryProducts = (companyId: string, articleNumbers: string[], updateProducts?: boolean) =>
  apiFetch<Product[]>(
    `/companies/${companyId}/productimport/handleTemporaryProducts?updateProducts=${updateProducts}`,
    articleNumbers,
    HTTPMethod.PUT
  );
export const postOnlyValidateHsCodesAsync = (
  companyId: string,
  hsCodeValidationItems: HsCodeValidationItem[]
): Promise<ValidateHsCodesAsyncResponse[]> =>
  apiFetch<ValidateHsCodesAsyncResponse[]>(
    `/companies/${companyId}/products/productClassifications/validate/onlyValidate`,
    hsCodeValidationItems,
    HTTPMethod.POST
  );

export const saveValidations = (
  companyId: string,
  productInformation: SaveValidation[]
) =>
  apiFetch(
    `/companies/${companyId}/products/productClassifications/validate/saveValidations`,

    productInformation
    ,
    HTTPMethod.POST
  );

export const getUnprocessedValidations = (
  companyId: string
): Promise<HsCodeValidationBatch[]> =>
  apiFetch<HsCodeValidationBatch[]>(
    `/companies/${companyId}/products/productClassifications/validate/getUnprocessedValidations`
  );

export const classifyProductsWithHsCodesFromAi = (companyId: string, productIds: string [], query: ProductClassificationFromAiQuery) =>
  apiFetch<ProductListContainerModel>(`/companies/${companyId}/products/classifyProductsWithHsCodesFromAi${!!query ? `?${queryString.stringify(query)}` : ''}`, productIds, HTTPMethod.PUT);

export const translateProducts = (companyId: string, productIds: string [], from: string, to: string) =>
  apiFetch<ProductListContainerModel>(`/companies/${companyId}/products/translateProducts?from=${from}&to=${to}`, productIds, HTTPMethod.PUT);

export const getListOfAvailableLanguagesForProductTranslation = (companyId: string) =>
  apiFetch<GoogleTranslateLanguage []>(`/companies/${companyId}/products/listOfAvailableLanguagesForProductTranslation`);

export const aiFormClassifyProduct = (companyId: string, product: PartialProduct) =>
  apiFetch<PartialProduct>(`/companies/${companyId}/products/aiFormClassifyProduct`, product, HTTPMethod.POST);