import { Reducer } from 'redux';
import { over, lensPath, append, set, subtract } from 'ramda';
import {
  User,
  Company,
  PartialCompany,
  Country,
  Currency,
  AppVersion,
  CompanyUser,
  PageOptions,
  Invitation,
  Agreement,
  ImportFileListCollection,
  IndustryNews,
  ApiKey,
  Customer,
  CollectionInvoice,
  CompanyContact,
  CrmCompany,
  Subscription,
  CompanySubscription,
} from 'integrations/crossborderit';
import { replaceBy, removeById } from 'utility';
import { BreadcrumbItem } from 'components/header';
import {
  setAppData,
  savedIsSidebarCollapsed,
  setBreadcrumbs,
  setCompanyUsers,
  setCompanyContacts,
  addCompanyUser,
  deleteCompanyUser,
  replaceCompanyUser,
  setCompany,
  setAdminData,
  setPendingCompanyAgreements,
  setSignedCompanyAgreements,
  setAsyncResource,
  setPageOptions,
  addCompanyContact,
  replaceCompanyContact,
  deleteCompanyContact,
  setNumberOfCompanyTestConsolidationsCorrections,
  setNumberOfCompanyConsolidationsCorrections,
  decreaseNumberOfCompanyTestConsolidationsCorrections,
  decreaseNumberOfCompanyConsolidationsCorrections,
} from './actions';
import { AsyncResource, AsyncStatus } from './interfaces';

export interface AppState {
  status?: AsyncStatus;
  isSidebarCollapsed?: boolean;
  user?: User;
  countries?: AsyncResource<Country[]>;
  crmCompanies?: { [companyId: string]: AsyncResource<CrmCompany[]>};
  currencies?: Currency[];
  companiesUserCanAccess?: AsyncResource<PartialCompany[]>;
  companies?: { [companyId: string]: AsyncResource<Company> };
  companyUsers?: { [companyId: string]: AsyncResource<CompanyUser[]> };
  companyContacts?: { [companyId: string]: AsyncResource<CompanyContact[]> };
  companyAgreements?: {
    [companyId: string]: {
      pendingAgreements?: AsyncResource<Agreement[]>;
      signedAgreements?: AsyncResource<Agreement[]>;
    };
  };
  iossOrderInformationFiles?: {
    [companyId: string]: AsyncResource<ImportFileListCollection[]>;
  };
  apiKeys?: {
    [companyId: string]: AsyncResource<ApiKey[]>
  }
  companyCustomers?: {
    [companyId: string]: AsyncResource<Customer[]>
  }
  breadcrumbs?: BreadcrumbItem[];
  appVersion?: AppVersion;
  pageOptions?: PageOptions;
  admin?: {
    status: AsyncStatus;
    companyInvitations: Invitation[];
  };
  industryNews?: AsyncResource<IndustryNews[]>;
  companyInvoices?: CollectionInvoice[];
  subscriptions?: AsyncResource<Subscription []> [];
  companySubscriptions?: AsyncResource<CompanySubscription []> [];
  numberOfCompanyConsolidationsCorrections?: { [companyId: string]: AsyncResource<number> },
  numberOfCompanyTestConsolidationsCorrections?: { [companyId: string]: AsyncResource<number> },
}

export const createInitialState = (): Partial<AppState> => ({
  status: AsyncStatus.NotInitialized,
  user: {} as User,
  companyUsers: {},
  companies: {},
  currencies: [],
  countries: {},
  crmCompanies: {},
  breadcrumbs: [],
  subscriptions: [],
  companySubscriptions: [],
  numberOfCompanyConsolidationsCorrections: {},
  numberOfCompanyTestConsolidationsCorrections: {},
  isSidebarCollapsed: JSON.parse(
    localStorage.getItem('isSidebarCollapsed') || 'false'
  ),
  appVersion: {
    environment: '',
    version: '',
  },
  pageOptions: {
    status: AsyncStatus.NotInitialized,
    data: {
      addressTypes: { multiSelect: false, options: [] },
      billingFrequencies: { multiSelect: false, options: [] },
      carrierCountryRoles: { multiSelect: false, options: [] },
      classificationModes: { multiSelect: false, options: [] },
      codeFormatterTypes: { multiSelect: false, options: [] },
      companyContactTypes: { multiSelect: false, options: [] },
      companyCompanyRoles: { multiSelect: false, options: [] },
      companyCategories: { multiSelect: false, options: [] },
      countryDataType: { multiSelect: false, options: [] },
      customsBrokerCountryRoles: { multiSelect: false, options: [] },
      feeCalculationTypes: { multiSelect: false, options: [] },
      feeTriggerTypes: { multiSelect: false, options: [] },
      importedTypeOfData: { multiSelect: false, options: [] },
      importMethods: { multiSelect: false, options: [] },
      keyDomains: { multiSelect: false, options: [] },
      numberFormattingOptions: { multiSelect: false, options: [] },
      paymentTerms: { multiSelect: false, options: [] },
      phoneTypes: { multiSelect: false, options: [] },
      productConditions: { multiSelect: false, options: [] },
      productIdentificationNumberTypes: { multiSelect: false, options: [] },
      productImportProblems: { multiSelect: false, options: [] },
      rateTypes: { multiSelect: false, options: [] },
      webShopType: { multiSelect: false, options: [] },
    },
  },
  admin: {
    status: AsyncStatus.NotInitialized,
    companyInvitations: [],
  },
});

export const rootReducer: Reducer<AppState> = (
  state = {},
  action
): AppState => {
  switch (action.type) {
    case setAppData.type: {
      return {
        ...state,
        status: action.status || AsyncStatus.NotInitialized,
        companiesUserCanAccess: action.companiesUserCanAccess || [],
        user: action.user,
        currencies: action.currencies || [],
        appVersion: action.appVersion,
        pageOptions: { data: action.pageOptions },
      };
    }

    case setPageOptions.type: {
      return {
        ...state,
        pageOptions: {
          status: action.status || AsyncStatus.NotInitialized,
          data: action.pageOptions,
        },
      };
    }

    case savedIsSidebarCollapsed.type:
      return {
        ...state,
        isSidebarCollapsed: action.isCollapsed,
      };

    case setBreadcrumbs.type:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };

    case setAdminData.type: {
      const { status = AsyncStatus.NotInitialized, companyInvitations = [] }
        = action;
      const companyPath = lensPath(['admin']);
      return set(companyPath, { status, companyInvitations }, state);
    }

    case setCompany.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        company = {},
      } = action;
      const companyPath = lensPath(['companies', companyId]);
      return set(companyPath, { status, data: company }, state);
    }

    case setCompanyUsers.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        users = [],
      } = action;
      const companyUsersPath = lensPath(['companyUsers', companyId]);
      return set(companyUsersPath, { status, data: users }, state);
    }

    case addCompanyUser.type:
      return over(
        lensPath(['companyUsers', action.companyId, 'data']),
        append(action.user),
        state
      );

    case replaceCompanyUser.type:
      return over(
        lensPath(['companyUsers', action.companyId, 'data']),
        replaceBy('userId', action.user),
        state
      );

    case deleteCompanyUser.type:
      return over(
        lensPath(['companyUsers', action.companyId, 'data']),
        removeById(action.userId),
        state
      );

    case setCompanyContacts.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        contacts = [],
      } = action;
      const companyContactsPath = lensPath(['companyContacts', companyId]);
      return set(companyContactsPath, { status, data: contacts }, state);
    }

    case addCompanyContact.type:
      return over(
        lensPath(['companyContacts', action.companyId, 'data']),
        append(action.contact),
        state
      );

    case replaceCompanyContact.type:
      return over(
        lensPath(['companyContacts', action.companyId, 'data']),
        replaceBy('id', action.contact),
        state
      );

    case deleteCompanyContact.type:
      return over(
        lensPath(['companyContacts', action.companyId, 'data']),
        removeById(action.contactId),
        state
      );

    case setPendingCompanyAgreements.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        agreements = [],
      } = action;
      const pendingCompanyAgreementsPath = lensPath([
        'companyAgreements',
        companyId,
        'pendingAgreements',
      ]);
      return set(
        pendingCompanyAgreementsPath,
        { status, data: agreements },
        state
      );
    }

    case setSignedCompanyAgreements.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        agreements = [],
      } = action;
      const signedCompanyAgreementsPath = lensPath([
        'companyAgreements',
        companyId,
        'signedAgreements',
      ]);
      return set(
        signedCompanyAgreementsPath,
        { status, data: agreements },
        state
      );
    }

    case setNumberOfCompanyTestConsolidationsCorrections.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        consolidationCount = 0,
      } = action;
      const numberOfCompanyTestConsolidationsCorrectionsPath = lensPath(['numberOfCompanyTestConsolidationsCorrections',
        companyId,
      ]);
      return set(
        numberOfCompanyTestConsolidationsCorrectionsPath,
        { status, data: consolidationCount },
        state
      );
    }

    case setNumberOfCompanyConsolidationsCorrections.type: {
      const {
        companyId,
        status = AsyncStatus.NotInitialized,
        consolidationCount = 0,
      } = action;
      const numberOfCompanyConsolidationsCorrectionsPath = lensPath(['numberOfCompanyConsolidationsCorrections',
        companyId,
      ]);
      return set(
        numberOfCompanyConsolidationsCorrectionsPath,
        { status, data: consolidationCount },
        state
      );
    }

    case decreaseNumberOfCompanyTestConsolidationsCorrections.type: {
      const { companyId, amount = 0 } = action;
      const newState = over(
        lensPath(['numberOfCompanyTestConsolidationsCorrections', companyId, 'data']),
        (currentValue) => subtract(currentValue, amount),
        state
      );
      return newState;
    };

    case decreaseNumberOfCompanyConsolidationsCorrections.type: {
      const { companyId, amount = 0 } = action;
      const newState = over(
        lensPath(['numberOfCompanyConsolidationsCorrections', companyId, 'data']),
        (currentValue) => subtract(currentValue, amount),
        state
      );
      return newState;
    };

    case setAsyncResource.type: {
      const { storePath, status, data } = action as unknown as {
        storePath: (string | number)[];
        status: AsyncStatus;
        data: any;
      };

      const lens = lensPath(storePath);
      const asyncResource = { status, data };

      return set(lens, asyncResource, state);
    }

    default:
      return state;
  }
};
