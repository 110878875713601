import { intersection } from 'ramda';
import {
  User,
  Company,
  CompanyUser,
  UserRole,
  CompanyCategory,
  CompanyUserRole,
  SubscriptionModule,
} from 'integrations/crossborderit';
import { isUserInRole } from 'store';

export interface IsAuthorizedProps {
  user?: User;
  requiredUserRoles?: UserRole[];
  company?: Company;
  requiredCompanyCategories?: CompanyCategory[];
  companyUsers?: CompanyUser[];
  requiredCompanyUserRoles?: CompanyUserRole[];
  requiredActiveCompanySubscriptionModules?: SubscriptionModule [],
}

export const isAuthorized = ({
  user,
  requiredUserRoles,
  companyUsers,
  requiredCompanyUserRoles,
  company,
  requiredCompanyCategories,
  requiredActiveCompanySubscriptionModules,
}: IsAuthorizedProps = {}) => {
  if ((user?.role || []).includes(UserRole.Employee)) return true;
  if ((user?.role || []).includes(UserRole.SuperAdmin)) return true;
  if (Array.isArray(requiredUserRoles)) {
    return isUserInRole(user, requiredUserRoles);
  }

  if (Array.isArray(requiredActiveCompanySubscriptionModules)) {
    const activeCompanySubscriptionModules = company?.activeCompanySubscriptionModules || [];
    if (!!intersection(requiredActiveCompanySubscriptionModules, activeCompanySubscriptionModules).length){
      return true;
    };
  }

  if (Array.isArray(requiredCompanyCategories)) {
    const companyCategories = company?.companyCategories || [];
    return !!intersection(requiredCompanyCategories, companyCategories).length;
  }

  if (Array.isArray(requiredCompanyUserRoles)) {
    const companyUser = companyUsers
      ?.find?.(companyUser => companyUser?.userId === user?.sub);

    const companyUserRoles = companyUser?.effectiveUserRoles || [];
    return !!intersection(companyUserRoles, requiredCompanyUserRoles).length;
  }

  return true;
};
