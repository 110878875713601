import { apiFetch, HTTPMethod } from '../fetch';
import { StatusOfVatReturnJob, VatReturn, VatReturnJob } from './interfaces';

export const getStatusOfVatReturnJob = (): Promise<StatusOfVatReturnJob> => apiFetch('/iossjob/VatReturns/');

export const getIossVatReturnJobs = (): Promise<VatReturnJob[]> => apiFetch('/iossjob/VatReturns/list/');

export const getListOfAllVatReturnsForJob = (
  id: string
): Promise<VatReturn[]> => apiFetch(`/iossjob/VatReturns/${id}/list/`);

export const putIossVatReturnJob = (
  year: number,
  month: number,
  detailAnalysis: boolean,
  replaceExistingData: boolean
): Promise<VatReturnJob[]> => apiFetch(
  `/iossjob/VatReturns?year=${year}&month=${month}&detailAnalysis=${detailAnalysis}&replaceExistingData=${replaceExistingData}`,
  undefined,
  HTTPMethod.PUT
);
