import * as Api from 'integrations/crossborderit';
import {
  ChartGroupedPeriod,
  DateRange,
  DigitalCustomsDayWiseReportOrders,
  EarningsAndVatByCompanyAndDate,
  PlatformType,
} from 'integrations/crossborderit/analytics';
import { useTranslation } from 'react-i18next';
import { DefaultPalette } from '@fluentui/react';
import { stringToColour } from 'utility/stringToColor';
import { CollectionInvoiceStatus, CollectionInvoiceType, Country } from 'integrations/crossborderit';
import { useCallback } from 'react';
import { IVerticalStackedChartProps } from '@fluentui/react-charting';
import { groupBy, map, pipe, prop } from 'ramda';
import moment from 'moment';

export const useAnalytics = () => {
  const { t } = useTranslation();

  const getEarningsAndVatByCompanyAndDateAllFeeTypes = useCallback(
    (dateRange: DateRange, platform: PlatformType = PlatformType.All) =>
      Api.getEarningsAndVatByCompanyAndDateAllFeeTypes(
        dateRange.from,
        dateRange.to,
        platform
      ),
    []
  );

  const getAllDestinationCountries = useCallback(
    (dateRange: DateRange, platform: PlatformType = PlatformType.All) =>
      Api.getAllDestinationCountries(dateRange.from, dateRange.to, platform).then(
        (datas) =>
          Object.keys(datas).map((key) => ({
            x: key,
            y: datas[key],
            color: DefaultPalette.blue,
            legend: t('Total orders'),
          }))
      ),
    [t]
  );

  const getAllUnpaidInvoices = useCallback(
    (invoiceType: CollectionInvoiceType, dateRange: DateRange) =>
      Api.getCollectionInvoices(invoiceType, dateRange.from, dateRange.to).then(
        (datas) => {
          const unpaidInvoices = datas?.filter(invoice => invoice.status === CollectionInvoiceStatus.SentToCustomer);
          const overDueUnpaidInvoices = unpaidInvoices.filter(invoice => moment(invoice.dueDate).isBefore(moment()));

          let invoicesByDate: IVerticalStackedChartProps[] = [];

          const groupByDueDateCompanyName = pipe(
            groupBy(prop('dueDate') as any),
            map(groupBy(prop('companyName') as any)) as any
          );

          const groupedInvoices: any = groupByDueDateCompanyName(overDueUnpaidInvoices);

          invoicesByDate = Object.keys(groupedInvoices).map((key) => ({
            xAxisPoint: moment(key).format('YYYY-MM-DD'),
            chartData: Object.values(groupedInvoices[key]).map((companysInvoices: any) => ({
              legend: companysInvoices[0].companyName,
              data: companysInvoices.length,
              xAxisCalloutData: moment(key).format('YYYY-MM-DD'),
              yAxisCalloutData: `${companysInvoices.length} invoices`,
              color: stringToColour(companysInvoices[0].companyName),
            })),
          }));
          return invoicesByDate;
        }
      ),
    []
  );

  const getDestinationCountriesForEachCompany = useCallback(
    (companyId: string, dateRange: DateRange) =>
      Api.getDestinationCountriesForEachCompany(
        companyId,
        dateRange.from,
        dateRange.to
      ).then((datas) =>
        Object.keys(datas).map((key) => ({
          x: key,
          y: datas[key],
          color: DefaultPalette.blue,
          legend: t('Total orders'),
        }))
      ),
    [t]
  );

  const getIossRegistrationsByPeriod = useCallback(
    (dateRange: DateRange) =>
      Api.getIossRegistrationsByPeriod(dateRange.from, dateRange.to).then(
        (datas) => ({
          data: datas.map((data) => ({
            x: data.transactionDate.slice(0, -9),
            y: data.count,
            color: DefaultPalette.blue,
            legend: t('New IOSS registrations'),
          })),
        })
      ),
    [t]
  );

  const getIossRegistrationsByPeriodAndCountry = useCallback(
    (dateRange: DateRange) =>
      Api.getIossRegistrationsByPeriodAndCountry(
        dateRange.from,
        dateRange.to
      ).then((datas) =>
        Array.from(new Set(datas.map((data) => data.iossStartDate)))
          .map((datasDate) =>
            datas.filter((data) => data.iossStartDate === datasDate)
          )
          .map((data2) => ({
            xAxisPoint: data2[0].iossStartDate.slice(0, -9),
            chartData: data2.map((data3) => ({
              legend: data3.country,
              data: data3.count,
              xAxisCalloutData: data2[0].iossStartDate.slice(0, -9),
              yAxisCalloutData: `${data3.count} registrations`,
              color: stringToColour(data3.country),
            })),
          }))
      ),
    []
  );

  const transformEarningsAndVatByCompanyAndDateAllFeeTypesVertical
    = useCallback(
      (datas: EarningsAndVatByCompanyAndDate) => ({
        vat: Array.from(
          new Set(datas.vat.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.vat.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.vatAmount,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.vatAmount} € VAT`,
              color: stringToColour(data.companyName),
            })),
          })),

        orders: Array.from(
          new Set(datas.earnings.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.earnings.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.count,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.count} Orders`,
              color: stringToColour(data.companyName),
            })),
          })),

        revenue: Array.from(
          new Set(datas.earnings.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.earnings.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.revenue,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.revenue} € Revenue`,
              color: stringToColour(data.companyName),
            })),
          })),
      }),
      []
    );

  const transformOrdersByCompanyAndDateAllFeeTypes = useCallback(
    (datas: EarningsAndVatByCompanyAndDate) =>
      Array.from(new Set(datas.earnings.map((data) => data.companyName).sort()))
        .map((datasDate) =>
          datas.earnings.filter((data) => data.companyName === datasDate)
        )
        .map((data) => ({
          companyName: data[0].companyName,
          companyId: data[0].companyId,
          count: data.reduce(
            (previousValue, currentValue) => previousValue + currentValue.count,
            0
          ),
        })),
    []
  );

  const transformEarningsAndVatByCompanyAndDateAllFeeTypesHorizontal
    = useCallback(
      (datas: EarningsAndVatByCompanyAndDate, dateRange: DateRange) => {
        const highestXValues = {
          vat: Math.max(
            ...Array.from(
              new Set(datas.vat.map((data) => data.companyName).sort())
            )
              .map((datasDate) =>
                datas.vat.filter((data) => data.companyName === datasDate)
              )
              .map((data) =>
                data.reduce(
                  (previousValue, currentValue) =>
                    previousValue + currentValue.vatAmount,
                  0
                )
              )
          ),
          orders: Math.max(
            ...Array.from(
              new Set(datas.earnings.map((data) => data.companyName).sort())
            )
              .map((datasDate) =>
                datas.earnings.filter((data) => data.companyName === datasDate)
              )
              .map((data) =>
                data.reduce(
                  (previousValue, currentValue) =>
                    previousValue + currentValue.count,
                  0
                )
              )
          ),

          revenue: Math.max(
            ...Array.from(
              new Set(datas.earnings.map((data) => data.companyName).sort())
            )
              .map((datasDate) =>
                datas.earnings.filter((data) => data.companyName === datasDate)
              )
              .map((data) =>
                data.reduce(
                  (previousValue, currentValue) =>
                    previousValue + currentValue.revenue,
                  0
                )
              )
          ),
        };

        return {
          vat: Array.from(
            new Set(datas.vat.map((data) => data.companyName).sort())
          )
            .map((datasDate) =>
              datas.vat.filter((data) => data.companyName === datasDate)
            )
            .map((data) => ({
              chartTitle: data[0].companyName,
              companyId: data[0].companyId,
              chartData: [
                {
                  legend: data[0].companyName,
                  horizontalBarChartdata: {
                    x:
                      Math.round(
                        data.reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue.vatAmount,
                          0
                        ) * 100
                      ) / 100,
                    y: highestXValues.vat,
                  },
                  xAxisCalloutData: `${dateRange.from.slice(
                    0,
                    -14
                  )} - ${dateRange.to.slice(0, -14)}`,
                  yAxisCalloutData: `${Math.round(
                    data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.vatAmount,
                      0
                    ) * 100
                  ) / 100
                  }${t('€ VAT')}`,
                  color: stringToColour(data[0].companyName),
                },
              ],
            })),

          orders: Array.from(
            new Set(datas.earnings.map((data) => data.companyName).sort())
          )
            .map((datasDate) =>
              datas.earnings.filter((data) => data.companyName === datasDate)
            )
            .map((data) => ({
              chartTitle: data[0].companyName,
              companyId: data[0].companyId,
              chartData: [
                {
                  legend: data[0].companyName,
                  horizontalBarChartdata: {
                    x: data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.count,
                      0
                    ),
                    y: highestXValues.orders,
                  },
                  xAxisCalloutData: `${dateRange.from.slice(
                    0,
                    -14
                  )} - ${dateRange.to.slice(0, -14)}`,
                  yAxisCalloutData: `${data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.count,
                    0
                  )} ${t('Orders')}`,
                  color: stringToColour(data[0].companyName),
                },
              ],
            })),

          revenue: Array.from(
            new Set(datas.earnings.map((data) => data.companyName).sort())
          )
            .map((datasDate) =>
              datas.earnings.filter((data) => data.companyName === datasDate)
            )
            .map((data) => ({
              chartTitle: data[0].companyName,
              companyId: data[0].companyId,
              chartData: [
                {
                  legend: data[0].companyName,
                  horizontalBarChartdata: {
                    x:
                      Math.round(
                        data.reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue.revenue,
                          0
                        ) * 100
                      ) / 100,
                    y: highestXValues.revenue,
                  },
                  xAxisCalloutData: `${dateRange.from.slice(
                    0,
                    -14
                  )} - ${dateRange.to.slice(0, -14)}`,
                  yAxisCalloutData: `${Math.round(
                    data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.revenue,
                      0
                    ) * 100
                  ) / 100
                  }${t('€ Revenue')}`,
                  color: stringToColour(data[0].companyName),
                },
              ],
            })),
        };
      },
      [t]
    );

  const getCompanyVatAndOrdersByPeriod = useCallback(
    (companyId: string, dateRange: DateRange) =>
      Api.getSalesByPeriod(companyId, dateRange.from, dateRange.to).then(
        (totalSalesDatas) => ({
          countries: Array.from(
            new Set(totalSalesDatas.map((data) => data.country).sort())
          ),
          orders: Array.from(
            new Set(totalSalesDatas.map((data) => data.transactionDate).sort())
          )
            .map((datasDate) =>
              totalSalesDatas.filter(
                (data) => data.transactionDate === datasDate
              )
            )
            .map((data) => ({
              x: data[0].transactionDate,
              y: data.reduce(
                (previousValue, currentValue) =>
                  previousValue + currentValue.count,
                0
              ),
              color: DefaultPalette.blue,
              legend: t('Orders'),
            })),
          vat: Array.from(
            new Set(totalSalesDatas.map((data) => data.transactionDate).sort())
          )
            .map((datasDate) =>
              totalSalesDatas.filter(
                (data) => data.transactionDate === datasDate
              )
            )
            .map((data) => ({
              x: data[0].transactionDate,
              y:
                Math.round(
                  data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.vatValue,
                    0
                  ) * 100
                ) / 100,
              color: DefaultPalette.blue,
              legend: t('€ VAT'),
            })),
        })
      ),
    [t]
  );

  const getSalesByDestinationCountry = useCallback(
    (companyId: string, dateRange: DateRange, selectedCountry: Country) =>
      Api.getSalesByPeriodAndDestination(
        companyId,
        dateRange.from,
        dateRange.to,
        selectedCountry.alpha2Code
      ).then((salesPerDestinationCountryDatas) => ({
        vat: salesPerDestinationCountryDatas.map((datas) => ({
          x: datas.transactionDate,
          y: datas.vatValue,
          legend: t('€ VAT'),
          color: DefaultPalette.blue,
        })),
        orders: salesPerDestinationCountryDatas.map((datas) => ({
          x: datas.transactionDate,
          y: datas.count,
          legend: t('Orders'),
          color: DefaultPalette.blue,
        })),
        countries: salesPerDestinationCountryDatas.map(
          (datas) => datas.country
        ),
      })),
    [t]
  );

  const getEarningsAndVatByCompanyAndDate = useCallback(
    (dateRange: DateRange, feeType: string, platform: PlatformType = PlatformType.All) =>
      Api.getEarningsAndVatByCompanyAndDate(
        dateRange.from,
        dateRange.to,
        feeType,
        platform
      ).then((datas) => ({
        vat: Array.from(
          new Set(datas.vat.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.vat.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.vatAmount,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.vatAmount} € VAT`,
              color: stringToColour(data.companyName),
            })),
          })),

        orders: Array.from(
          new Set(datas.earnings.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.earnings.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.count,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.count} Orders`,
              color: stringToColour(data.companyName),
            })),
          })),

        revenue: Array.from(
          new Set(datas.earnings.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.earnings.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.companyName,
              companyId: data.companyId,
              data: data.revenue,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.revenue} € Revenue`,
              color: stringToColour(data.companyName),
            })),
          })),
      })),
    []
  );

  const getVatAndOrdersBySourceCountry = useCallback(
    (dateRange: DateRange, platform: PlatformType = PlatformType.All) =>
      Api.getSalesByPeriodAdmin(dateRange.from, dateRange.to, platform).then((datas) => ({
        vat: Array.from(
          new Set(datas.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.country,
              data: data.vatValue,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.vatValue} € VAT`,
              color: stringToColour(data.country),
            })),
          })),

        orders: Array.from(
          new Set(datas.map((data) => data.transactionDate).sort())
        )
          .map((datasDate) =>
            datas.filter((data) => data.transactionDate === datasDate)
          )
          .map((data) => ({
            xAxisPoint: data[0].transactionDate,
            chartData: data.map((data) => ({
              legend: data.country,
              data: data.count,
              xAxisCalloutData: data.transactionDate,
              yAxisCalloutData: `${data.count} Orders`,
              color: stringToColour(data.country),
            })),
          })),
      })),
    []
  );

  const getDayWiseReportForDigitalCustomsOrders = useCallback(
    (fromDate: string,
      toDate: string,
      groupedPeriod: ChartGroupedPeriod,
      companyId?: string
    ) => {
      const formatXAxisPoint = (item: DigitalCustomsDayWiseReportOrders, groupedPeriod: ChartGroupedPeriod) => {
        if (groupedPeriod === ChartGroupedPeriod.Monthly) {
          return moment(item.date).format('MMMM');
        } else if (groupedPeriod === ChartGroupedPeriod.Weekly) {
          return t('Week ') + moment(item.date).isoWeek().toString();
        } else {
          return item.date;
        }
      };

      return Api.getDayWiseReportForDigitalCustomsOrders({
        fromDate,
        toDate,
        groupedPeriod,
        companyId,
      }).then((datas) => datas
        .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        .map((item) => ({
          xAxisPoint: formatXAxisPoint(item, groupedPeriod),
          chartData: [
            {
              legend: item.companyName,
              companyId: item.companyId,
              data: item.orders,
              xAxisCalloutData: formatXAxisPoint(item, groupedPeriod),
              yAxisCalloutData: `${item.orders} Orders`,
              color: stringToColour(item.companyId),
            },
          ],
        })));
    }, [t]);

  return {
    getIossRegistrationsByPeriod,
    getIossRegistrationsByPeriodAndCountry,
    getCompanyVatAndOrdersByPeriod,
    getSalesByDestinationCountry,
    getEarningsAndVatByCompanyAndDate,
    getVatAndOrdersBySourceCountry,
    getDestinationCountriesForEachCompany,
    getAllDestinationCountries,
    getEarningsAndVatByCompanyAndDateAllFeeTypes,
    transformEarningsAndVatByCompanyAndDateAllFeeTypesVertical,
    transformEarningsAndVatByCompanyAndDateAllFeeTypesHorizontal,
    transformOrdersByCompanyAndDateAllFeeTypes,
    getAllUnpaidInvoices,
    getDayWiseReportForDigitalCustomsOrders,
  };
};
