import { apiFetch, HTTPMethod } from '../fetch';
import { CollectionInvoice } from '../collectionInvoices';
const getInvoicesByCompany = (companyId: string) =>
  apiFetch<CollectionInvoice[]>(`/financials/${companyId}/invoices`);

const deleteInvoiceEmailAddress = (companyId: string, billingInformationId: string, id: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail/${id}`, undefined, HTTPMethod.DELETE);

const putInvoiceEmailAddress = (companyId: string, billingInformationId: string, id: string, updatedInvoiceEmail: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail/${id}`, updatedInvoiceEmail, HTTPMethod.PUT);

const postInvoiceEmailAddress = (companyId: string, billingInformationId: string, newInvoiceEmail: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail`, newInvoiceEmail, HTTPMethod.POST);

export {
  getInvoicesByCompany,
  deleteInvoiceEmailAddress,
  postInvoiceEmailAddress,
  putInvoiceEmailAddress,
};