import { useEffect, useCallback, useMemo } from 'react';
import { is, isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ImportFileListCollection, IossOrderRefund } from './interfaces';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { useCrossborderitApi } from 'integrations/crossborderit/useCrossborderitApi';
import { HTTPMethod } from 'integrations/crossborderit/fetch';
import { removeBy } from 'utility';

interface Options {
  initialize?: boolean;
}

const getDefaultOptions = (): Options => ({
  initialize: true,
});

export const useIossOrderInformation = (
  companyId: string,
  options: Options = getDefaultOptions()
) => {
  const iossOrderInformationStorePath = useMemo(() => ['iossOrderInformationFiles', companyId], [companyId]);
  const { initialize } = { ...getDefaultOptions(), ...options };
  const { t } = useTranslation();
  const { api, apiFormData } = useCrossborderitApi();
  const dispatch = useDispatch();

  const {
    status = AsyncStatus.NotInitialized,
    data: iossOrderInformationFiles = [],
  } = useSelector(selectAsyncResource<ImportFileListCollection[]>(
    [],
    iossOrderInformationStorePath
  ));

  const loadIossOrderInformationFiles = useCallback((companyId: string) => {
    if (!companyId) setAsyncResource(iossOrderInformationStorePath, AsyncStatus.Error);

    dispatch(setAsyncResource(iossOrderInformationStorePath, AsyncStatus.Loading));

    api<ImportFileListCollection[]>(`/companies/${companyId}/iossimport/`)
      .then(iossOrderInformationFiles => dispatch(setAsyncResource(
        iossOrderInformationStorePath,
        AsyncStatus.Success,
        iossOrderInformationFiles
      )))
      .catch(() => dispatch(setAsyncResource(
        iossOrderInformationStorePath,
        AsyncStatus.Error
      )));
  }, [dispatch, api, iossOrderInformationStorePath]);

  const deleteIossOrderInformationFile = useCallback((fileId: string) => {
    // This function assumes that the iossOrderInformationFiles have been loaded

    if (isNil(fileId) || !is(String, fileId)) {
      return Promise.reject(t('fileId is required'));
    }

    const file = iossOrderInformationFiles.find(file => file.fileId === fileId);

    if (!file) {
      return Promise.reject(t('No file with the provided fileId could be found'));
    }

    if (file.cannotBeDeleted) {
      return Promise.reject(t('File has been processed and cannot be deleted'));
    }

    return api(
      `/companies/${companyId}/iossimport?fileId=${fileId}`,
      undefined,
      HTTPMethod.DELETE
    ).then(() => dispatch(setAsyncResource(
      iossOrderInformationStorePath,
      AsyncStatus.Success,
      removeBy('fileId', fileId, iossOrderInformationFiles)
    )));
  }, [iossOrderInformationFiles, iossOrderInformationStorePath, api, t, companyId, dispatch]);

  const deleteMultipleIossOrderInformationFiles = useCallback((files: ImportFileListCollection[]) => {

    const filteredFiles = files.filter(file => !isNil(file.fileId) || is(String, file.fileId) || file.fileId);

    if (!filteredFiles) {
      return Promise.reject(t('Something went wrong, no files were removed'));
    }

    if (filteredFiles.some(f => f.cannotBeDeleted)) {
      return Promise.reject(t('Some file is locked and cannot be deleted'));
    };

    const filesIds = filteredFiles.map(file => file.fileId);

    return api(
      `/companies/${companyId}/iossimport/deleteMultiple`,
      filesIds,
      HTTPMethod.DELETE
    ).then(() => dispatch(setAsyncResource(
      iossOrderInformationStorePath,
      AsyncStatus.Success,
      iossOrderInformationFiles.filter(file => !filesIds.some(id => id === file.fileId))
    )));
  }, [iossOrderInformationFiles, iossOrderInformationStorePath, api, t, companyId, dispatch]);

  const uploadIossOrderInformationFile = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);

    return apiFormData<ImportFileListCollection>(
      `/companies/${companyId}/iossimport/`,
      formData,
      HTTPMethod.POST
    )
      .catch((error: string | Error) => Promise.reject(({
        failedToImport: true,
        iossFailedToImportItems: [{
          rowIndex: 0, // error on columns is shown as title in error modal,
          errorMessages: is(String, error)
            ? [error]
            : [t('Could not perform import')],
        }],
      }) as unknown as ImportFileListCollection))
      .then((iossOrderInformationFile) => {

        if (iossOrderInformationFile.failedToImport) {
          return Promise.reject(iossOrderInformationFile);
        }

        dispatch(setAsyncResource(
          iossOrderInformationStorePath,
          AsyncStatus.Success,
          [iossOrderInformationFile, ...iossOrderInformationFiles]
        ));
      });
  };

  const postIossRefund = ( iossOrderDataEntryId: string, file?: any, iossOrderRefundReason: string = '' ) => {
    const formData = new FormData();
    formData.append('iossOrderDataEntryId', iossOrderDataEntryId);
    formData.append('uploadedFile', file);
    formData.append('iossOrderRefundReason', iossOrderRefundReason);

    return apiFormData<IossOrderRefund>(
      `/companies/${companyId}/iossimport/iossOrderRefund`,
      formData,
      HTTPMethod.POST
    );
  };

  const postIossFileRefund = ( iossImportFileId: string, file?: any, iossOrderRefundReason: string = '' ) => {
    const formData = new FormData();
    formData.append('iossImportFileId', iossImportFileId);
    formData.append('uploadedFile', file);
    formData.append('iossOrderRefundReason', iossOrderRefundReason);

    return apiFormData<ImportFileListCollection>(
      `/companies/${companyId}/iossimport/iossFileRefund`,
      formData,
      HTTPMethod.POST
    );
  };

  useEffect(() => {
    if (!initialize) return;
    if (status !== AsyncStatus.NotInitialized) return;
    loadIossOrderInformationFiles(companyId);
  }, [companyId, status, initialize, loadIossOrderInformationFiles]);

  return {
    status,
    iossOrderInformationFiles,
    loadIossOrderInformationFiles,
    deleteIossOrderInformationFile,
    deleteMultipleIossOrderInformationFiles,
    uploadIossOrderInformationFile,
    postIossRefund,
    postIossFileRefund,
  };
};
